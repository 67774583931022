/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import {graphql, useStaticQuery} from 'gatsby';
import React, {ReactNode} from 'react';
import {oc} from 'ts-optchain';
import {SiteTitleQueryQuery} from '../../graphqlTypes';
import Header from '../header';
import Footer from '@components/Footer';

import styles from './PageLayout.module.scss';

import '../layout.css';

interface Props {
  children: ReactNode
}

const useSiteTitle = () => {
    const data: SiteTitleQueryQuery = useStaticQuery(
        graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
    );
    return oc(data).site.siteMetadata.title('');
};

const Layout: React.FC<Props> = ({children}) => {
    const title = useSiteTitle();

    return (
        <>
            <Header siteTitle={title} />
            <div className={styles.container}>
                <main>{children}</main>
                <Footer />
            </div>
        </>
    );
};

export default Layout;
