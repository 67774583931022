import * as React from 'react';
import {Link as GatsbyLink} from 'gatsby';

import styles from './Link.module.scss';

interface Props {
    to: string;
    className?: string;
    rest?: any;
}

const Link: React.FC<Props> = ({
    to,
    className,
    ...props
}) => {
    const classes = [className, styles.link].join(' ');
    return (
        <GatsbyLink
            to={to}
            className={classes}
            {...props}
        />);
};

export default Link;
