import React from 'react';

import styled from 'styled-components';

interface Props {
    tag?: string;
    className?: string;
};

interface StyledProps {
    color?: string;
    bold?: boolean;
}

const Typography: React.FC<Props> = (props) => {
    const {
        tag = 'span',
        className,
        children,
        ...rest
    } = props;

    return React.createElement(tag, {className, ...rest}, children);
};

const _getColor = (color: string | undefined): string => {
    if (color) {
        return `var(--${color}, 'inherit')`;
    };
    return 'inherit';
};
export default styled(Typography)`
    color: ${(props: StyledProps): string => _getColor(props.color)};
    font-weight: ${(props: StyledProps): string => props.bold ? 'bold' : 'inherit'};
`;
