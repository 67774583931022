import * as React from 'react';
import {Link} from 'gatsby';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@components/toolkit/Button';
import OutlinedButton from '@components/toolkit/Button/OutlinedButton';
import Typography from '@components/Typography';
import {createClassString} from '@components/toolkit/utility';

import styles from './header.module.scss';

interface Props {
  siteTitle?: string;
}

const Header: React.FC<Props> = ({siteTitle}) => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleCloseMenu = (): void => {
        setMenuAnchorEl(null);
    };

    return (
        <header
            className={styles.header}
        >
            <div
                className={styles.container}
            >
                <h1
                    className={styles.headerText}
                >
                    <Button
                        to="/"
                    >
                        {siteTitle}
                    </Button>
                </h1>
                <div className={createClassString(styles.rightNav, styles.large)}>
                    <Button
                        to="/product"
                    >
                        Product
                    </Button>
                    <Button
                        to="/login" // this will likely lead to actual kkbo app
                    >
                        Log In
                    </Button>
                    <OutlinedButton
                        to="/signup" // this will likely lead to actual kkbo app
                    >
                        Sign Up
                    </OutlinedButton>
                </div>
                <Typography
                    tag="div"
                    color="secondary"
                    className={createClassString(styles.rightNav, styles.small)}
                >
                    <ClickAwayListener onClickAway={handleCloseMenu}>
                        <IconButton
                            color="inherit"
                            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
                                setMenuAnchorEl(event.currentTarget)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </ClickAwayListener>
                    <Menu
                        open={Boolean(menuAnchorEl)}
                        anchorEl={menuAnchorEl}
                    >
                        <MenuItem>
                            Product
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            to="/login"
                        >
                            Log In
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            to="/signup"
                        >
                            Sign Up
                        </MenuItem>
                    </Menu>
                </Typography>
            </div>
        </header>
    );
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
