const emptyArray: string[] | never[] = [];
export const createClassString = (...classes: (string | undefined)[]): string =>
    classes
        .filter((className): className is string => typeof className === 'string')
        .reduce((prev, cur: string): string[] => {
            if (cur) {
                return [...prev, ...cur.split(' ').filter((str: string) => str)];
            }
            return [...prev];
        }, emptyArray)
        .join(' ');
