import React from 'react';
import Link from '@components/toolkit/Navigation/Link';

import styles from './Button.module.scss';


type ColorTypes = 'primary' | 'secondary';
interface Props {
    to?: string;
    onClick?: <T, U>(param: T) => U;
    children?: JSX.Element | string;
    className?: string;
    color?: ColorTypes;
    rest?: any;
};

const Button = (props: Props): JSX.Element => {
    const {
        to,
        onClick,
        className,
        color,
        children,
        ...rest
    } = props;


    const colorClassName = color ? styles[color] : '';

    if (to) {
        return (
            <Link
                className={[styles.button, colorClassName, className].join(' ')}
                to={to}
                {...rest}
            >
                {children}
            </Link>
        );
    };
    return (
        <button
            className={[styles.button, colorClassName, className].join(' ')}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
