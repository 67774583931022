import React from 'react';
import Button from '@components/toolkit/Button';

import styles from './Footer.module.scss';

const Footer: React.FC = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.section}>
                <div className={styles.title}>
                    Get To Know Us
                </div>
                <div className={styles.info}>
                    <ul>
                        <li>
                            <Button to="/getToKnowUs/about" >About</Button>
                        </li>
                        <li>
                            <Button to="/getToKnowUs/team" >Team</Button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.section}>
                <div className={styles.title}>
                    Resources
                </div>
                <div className={styles.info}>
                    <ul>
                        <li>
                            <Button to="/resources/aboutKakeibo" >About Kakeibo</Button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.section}>
                <div className={styles.title}>
                    Contact Us
                </div>
            </div>
        </div>
    );
};

export default Footer;
