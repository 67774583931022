import React from 'react';
import Button from '@components/toolkit/Button';
import styles from './OutlinedButton.module.scss';

interface Props {
    children?: JSX.Element | string;
    to?: string;
    rest?: any;
};

const OutlinedButton = (props: Props): JSX.Element => {
    const {children, to, ...rest} = props;
    return (
        <Button
            className={styles.outlined}
            to={to}
            {...rest}
        >
            {children}
        </Button>);
};
export default OutlinedButton;
